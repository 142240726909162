import moment, { MomentInput } from 'moment'

export const formatBirthday = (date: Date): string =>
  moment(date).format('MMMM Do')

export const formatAnniversary = (date: Date): string =>
  moment(date).format('MMM Do, YYYY')

export const formatRangeDate = (myDate: Date) =>
  moment(myDate).format('YYYY-MM-DD')

export const formatDateTime = (myDate: Date) =>
  moment(myDate).format('MMM D, h:mma')

export const getDaysFromToday = (futureDay: Date | string): number => {
  return Math.floor(
    (new Date(futureDay).getTime() - new Date().getTime()) / (1000 * 3600 * 24),
  )
}
export const formatUsDate = (date: Date): string => {
  if (!date) {
    return ''
  }
  return date.toLocaleDateString('en-US')
}

export const getNormalizedBirthday = (birthday: string): Date | null => {
  if (!birthday) {
    return null
  }
  const normalizedBirthday = new Date(birthday)
  normalizedBirthday.setFullYear(new Date().getFullYear())
  return normalizedBirthday
}

export const isAfter = (date1: MomentInput, date2: MomentInput): boolean =>
  moment(date1).isAfter(date2)

export const timeFromNow = (date: MomentInput): string =>
  moment(date).fromNow(true)
