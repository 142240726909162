export const removeNonAlphaNumeric = (str: string): string =>
  str.replace(/[^A-Za-z0-9]/g, '')
export const HELLIP = String.fromCharCode(8230)
export const UP_ARROW = String.fromCharCode(94)
export const snakeCase = (raw: string): string => {
  const parts = raw.match(/([a-z]+)|([A-Z][a-z]+)/g)
  return (
    parts?.map(part => part.toLowerCase()).join('_') ||
    raw
      .toLowerCase()
      .split(/[\s-_]/)
      .join('_')
  )
}
export const removeSnakeCase = (raw: string | null | undefined): string => raw ? raw.replace(/[_]/, ' ') : '';

/*
  Takes in a string and returns that same string with the first letter capitalized.
  If it receives an empty string or a non-string, then it will return an empty string.
*/
export const upFirst = (arg: any): string => {
  if (typeof arg !== 'string' || !arg) return ''

  const [head, ...tail] = arg

  return head.toUpperCase() + tail.join('')
}
