import { GameCard, Reward } from 'ui/types/Games'
import { Assignment } from 'ui/types/GameTypes'

export interface Ranking {
  rank: number
  isSelf: boolean
  score: number
}

export interface Rules {
  metric: string
  goal: number
  unit: string
}

export interface Winner {
  id: number
  name: string
  imageUrl: string
}

export interface Player {
  ranking: number
  score: number
  earnedReward?: Reward
  awardCount?: number
}

export enum StatusType {
  Active = 'active',
  Upcoming = 'prelaunch',
  NeedsVerification = 'needs_verification',
  Completed = 'completed',
}

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
  None = 'none',
}

export interface Status {
  statusType: StatusType.Active | StatusType.Upcoming | StatusType.Completed
}

export interface GameResponse {
  currentUserScore: number
  id: number
  assign: Assignment
  coverImageUrl?: string
  name: string
  expiresAt: Date
  startsAt: Date
  status: Status['statusType']
  rankings: Ranking[]
  rewards: Array<{
    type: Reward['type']
    value: any // returns string amount if reward type is tokens OR object containing rarity of chest as property if reward type is chest
  }>
  playerInformation: Player
  rules: Rules
  totalScore: number
  type: GameCard['gameType']
  winner?: Winner
  isRewardImmediatelyDistributed?: boolean
  isSingleGoalReward?: boolean
  limitWinners?: number
  numberOfWinners?: number
}

export enum GameFilters {
  Active = 'active',
  Upcoming = 'upcoming',
  Completed = 'completed',
}

export interface Game {
  id: string
  name: string
  awardedAmount?: number
  endDateTime?: Date
  gameType: GameCard['gameType']
  assign: Assignment
  coverImageUrl?: string
  goal: number
  isCompleted?: boolean
  isCurrentUser?: boolean
  isUpcoming?: boolean
  metric: string
  place?: number
  prizeAmount: number
  prizeType: Reward['type']
  rewards: Reward[]
  status?: Status['statusType']
  startDateTime?: Date
  totalScore: number
  unit: string
  userId?: number
  userName?: string
  userImageUrl?: string
  isRewardImmediatelyDistributed?: boolean
  isSingleGoalReward?: boolean
  earnedReward?: Reward
  awardCount?: number
  limitWinners?: number
  numberOfWinners?: number
}

export const gamesFilterOptions = [...Object.keys(GameFilters)]

export const gamesFilterOptionValues: GameFilters[] = gamesFilterOptions.map(
  value => GameFilters[value],
)
export interface Rewards {
  type: string
  value?: string
  receiving_rank: number
  claimedBy?: number
  claimedAt?: string
  receivingRank?: number
  jackpot?: number
  chances?: [{ thing: string; count: string }]
}
