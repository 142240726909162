import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import Gleap from 'gleap'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { useRollbarPerson } from '@rollbar/react'
import { pick } from 'lodash'
import { ArcadeAuth, AUTH_STORAGE_KEY } from 'ui/types'
import { getIdentifiers } from 'ui/helpers'
import {
  getLinkLoginAuthObject,
  getTokenFromParams,
} from 'components/LoginWall/ChannelWall/AuthHelpers'
import { AppContext } from './MPAppContextProvider'
import { useRoles } from 'hooks'

const LoginWall = dynamic(
  async () => await import('../components/LoginWall/LoginWall'),
  {
    ssr: false,
  },
)

const LOGIN_ROUTES = [
  '/login-groupme',
  '/login-slack',
  '/login-via-link',
  '/login-via-magic-link',
  '/login',
]

export interface LocalAuthProps {
  get: () => ArcadeAuth
  set: (token: ArcadeAuth) => void
  clear: () => void
}
export interface AuthContextProps {
  auth: ArcadeAuth
  setAuth: Dispatch<SetStateAction<ArcadeAuth>>
  localAuth: LocalAuthProps
  logout: () => void
}
export const getLocalAuth = (): LocalAuthProps => {
  return {
    get: () => {
      const strAuth = window.localStorage.getItem(AUTH_STORAGE_KEY) || '{}'
      return JSON.parse(strAuth)
    },
    set: (auth: ArcadeAuth) => {
      const strAuth = JSON.stringify(auth) || '{}'
      window.localStorage.setItem(AUTH_STORAGE_KEY, strAuth)
    },
    clear: () => {
      window.localStorage.removeItem(AUTH_STORAGE_KEY)
    },
  }
}
export const AuthContext = createContext({} as AuthContextProps)

const AuthInterceptor: FC<PropsWithChildren> = ({ children }) => {
  const {
    auth,
    localAuth: { clear: clearLocalAuth },
  } = useContext(AuthContext)
  const router = useRouter()

  useEffect(() => {
    /*
      We are checking if router.isReady to ensure that any dynamic routes have resolved. For example if you navigate to /manage/players/28 the router will first have pathname = /manage/players/[id] before interpolating 28. 
      When router.isReady is true we can be sure that the URL will correctly have /28 instead of /[id]
    */
    if (router && !auth.token && router.isReady) {
      if (!LOGIN_ROUTES.includes(router.pathname)) {
        router
          .replace({
            pathname: '/login',
            query: { redirectTo: encodeURIComponent(router.asPath) },
          })
          .catch(err => {
            throw err
          })
      }
    }
  }, [auth.token, router.isReady])

  // If no one is logged in, we can just render <LoginWall /> and let things take their course
  if (!auth.token) return <LoginWall />

  /* if someone IS logged in but /login-via-link or /login-via-magic-link and
     payload or token query param is present, render <LoginWall /> instead of the app so that the machinery can
     handle the login attempt.  Clearing local auth isn't strictly needed,
     but is more sensical as an attempt to login SHOULD clear any current login */
  const { token: linkLoginToken } = getLinkLoginAuthObject()
  const magicLinkToken = getTokenFromParams()

  // query param will already be removed if login attempt is in progress
  const shouldOverrideLoggedInUser =
    /\/login-via/.test(router.route) && (linkLoginToken || magicLinkToken)

  if (shouldOverrideLoggedInUser) {
    clearLocalAuth()
    return <LoginWall />
  }

  // Attempt to render app only if auth.token present and !shouldOverrideLoggedInUser
  return <>{children}</>
}

export const MPAuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const localAuth = getLocalAuth()
  const loadAuth = localAuth.get()
  const [auth, setAuthState] = useState(loadAuth)
  const { identifyUserForAppcues } = useContext(AppContext)
  const role = useRoles(!!auth.token)

  const [currentUser, setCurrentUser] = useState({})
  useRollbarPerson(currentUser)

  // Call identify user on all pages to track
  if (auth?.id && window.Appcues) {
    identifyUserForAppcues(auth)
  }

  useEffect(() => {
    const pii = pick(auth, [
      'email',
      'entityId',
      'entityName',
      'fullName',
      'id',
    ])
    setCurrentUser(pii)

    if (Gleap.getInstance().initialized && auth.token && role) {
      const gleapKey = [getIdentifiers().server, pii.id].join('-')

      Gleap.identify(gleapKey, {
        name: pii.fullName,
        email: pii.email,
        companyName: pii.entityName,
        companyId: pii.entityId.toString(),
      })

      Gleap.updateContact({
        role,
        ...segmentsForUserId(pii.id),
      } as any)
    }
  }, [auth.token, role])

  const setAuth = (auth: ArcadeAuth): void => {
    setAuthState(auth)
    if (auth.token) {
      localAuth?.set?.(auth)
    } else {
      localAuth?.clear?.()
    }
  }

  const logout = (): void => {
    setAuth({} as ArcadeAuth)
    localStorage.removeItem('assumedUser')
    window.location.pathname = '/login'
  }

  return (
    <AuthContext.Provider value={{ auth, setAuth, logout, localAuth }}>
      <AuthInterceptor>{children}</AuthInterceptor>
    </AuthContext.Provider>
  )
}

/* Identify users as belonging to 50%, 33% or 25% sample groups, based off of userId.
   By comparing against the current month, each group will change throughout the year.
   This is a very inexact method, but was the lightest lift and we don't need to use it
*/
function segmentsForUserId(userId: number) {
  const currMonth = new Date().getMonth()

  return {
    inHalfOfUsers: userId % 2 === currMonth % 2 ? 'true' : 'false',
    inThirdOfUsers: userId % 3 === currMonth % 3 ? 'true' : 'false',
    inQuarterOfUsers: userId % 4 === currMonth % 4 ? 'true' : 'false',
  }
}

export default MPAuthProvider
