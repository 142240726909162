import { GenericIndexedObject } from './GenericIndexed'

export interface ArcadeAuth {
  token: string
  createdAt: string
  role: string
  id: number
  email: string
  firstName: string
  lastName: string
  fullName: string
  profileImage: string
  imageBlob: string
  imageUrl: string
  entityId: number
  entityName: string
  teamId: number
  parentEntityId: number
  locked: boolean
  features: GenericIndexedObject<boolean>
  endpoint: string
  isTeams?: boolean
  isArcade?: boolean
  platform?: string
}

export const AUTH_STORAGE_KEY = 'arcade.auth'
