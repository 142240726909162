import { useContext, useEffect, useState } from 'react'
import cubejs from '@cubejs-client/core'
import {
  CubeContext,
  useCubeQuery as cubeQueryHook,
} from '@cubejs-client/react'

/*
    Starting ~February 2023, a bug has started occurring where the app
    starting on a page that utilizes Cube would raise a fatal error.
    It was determined that passing <undefined> for the cubejsApi prop
    on CubeProvider below was causing this situation when child components
    would attempt to consume that Provider value and make the required calls.
    As a workaround, this thin wrapper utilizes a fake cubejsApi instance and
    skips every query.

    Once the async op to get the needed info for the cubejsApi is ready,
    this hook will rerender and operations will proceed unmodified
  */
export const useCubeQuery = (query, options = {}) => {
  const [cubeNotLoaded, setCubeNotLoaded] = useState(true)
  const cubeContext = useContext(CubeContext)

  const notLoadedOptions = {
    cubejsApi: cubejs('FAKE_TOKEN', { apiUrl: 'https://fake.endpoint' }),
    skip: true,
  }

  useEffect(() => {
    setCubeNotLoaded(!cubeContext?.cubejsApi)
  }, [cubeContext])

  return cubeQueryHook(query, {
    ...options,
    ...(cubeNotLoaded && notLoadedOptions),
  })
}
