import { get } from 'lodash'
import { AxiosResponse } from 'axios'

const KEY = 'reloadClientNextNavigation'

export const checkClientVersion = (response: AxiosResponse) => {
  const thisDeploySHA = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA
  const version = get(response, 'headers.x-client-version')

  if (!version || !thisDeploySHA || thisDeploySHA === version) return response

  markForReload()

  return response
}

const markForReload = () => localStorage.setItem(KEY, 'true')

export const maybeReloadOnNavigate = (url: string) => {
  if (!localStorage.getItem(KEY)) return

  window.location.href = url
  afterReload()
}

const afterReload = () => localStorage.removeItem(KEY)
