import { extendTheme } from '@chakra-ui/react'
import { boxShadows, colors, typography } from '@workplacearcade/styles'

import Drawer from './config/drawer'
import Checkbox from './config/checkbox'
import Input from './config/input'
import Popover from './config/popover'
import Modal from './config/modal'
import Radio from './config/radio'
import FormLabel from './config/form-label'
import NumberInput from './config/number-input'
import DatePicker from './DatePicker.styles'

const breakpoints = {
  xl: '1248px',
}

const theme = extendTheme({
  colors,
  breakpoints,
  shadows: {
    ...boxShadows,
  },
  ...typography,
  components: {
    Radio,
    Drawer,
    Input,
    Modal,
    NumberInput,
    Popover,
    FormLabel,
    Checkbox,
  },
  styles: {
    global: {
      ...DatePicker,
    },
  },
})

export default theme
