import { useEffect } from 'react'
import useSWR from 'swr'
import { arcadeApiClient } from '../apiClients'

const fetcher = (_generalKey, userId) =>
  arcadeApiClient.get(`/multi_platform/users/${userId}`).then(res => {
    // This route returns the logged in user if the id is not found; ignore
    if (res.data.user.id != userId) {
      throw new Error('User not found')
    }
    return res.data.user.fullName
  })

export const useUserInfo = (userId: number | string | null | undefined) => {
  const {
    data: fullName,
    error,
    mutate,
  } = useSWR(['basic-user-info', `${userId}`], fetcher, {
    isPaused: () => !userId,
    revalidateOnMount: false,
  })

  /*
   Shockingly, SWR does not do an initial fetch if revalidateOnMount is false
   This is a workaround until we upgrade SWR to a version that supports useImmutable, etc.
   https://github.com/vercel/swr/issues/455#issuecomment-643504686
  */
  useEffect(() => {
    if (!fullName && !error) mutate()
  }, [fullName])

  /* Also until we upgrade SWR: dont use isValidating as it will initially be false until
     the useEffect triggers a manual mutate. The latest version of SWR has isLoading prop
  */
  return { error, fullName, loading: !error && !fullName }
}
