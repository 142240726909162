import { FC, ReactNode } from 'react'
import { render, RenderResult } from '@testing-library/react'
import { ChakraProvider } from '@chakra-ui/react'
import theme from '../styles/theme'
import { MemoryRouterProvider } from 'next-router-mock/MemoryRouterProvider/next-13'

const Wrapper: FC = ({ children }: { children: ReactNode }) => (
  <MemoryRouterProvider>
    <ChakraProvider theme={theme}>{children}</ChakraProvider>
  </MemoryRouterProvider>
)
export const renderWithTheme = (ui): RenderResult => {
  return render(ui, { wrapper: Wrapper })
}
