import { arcadeApiClient } from '../apiClients'
import { AppContext } from 'providers'
import { useContext, useEffect, useState } from 'react'
import useSWR from 'swr'
import { NewsfeedItem } from 'types/NewsfeedItem'

const fetcher = (query): Promise<NewsfeedItem> => {
  return arcadeApiClient
    .get(`/multi_platform/newsfeed/${query}`)
    .then(res => res.data.newsfeedItem)
}

export const useNewsfeedItem = ({
  newsfeedItemId,
}: {
  newsfeedItemId: number
}) => {
  const [newsfeedItem, setNewsfeedItem] =
    useState<NewsfeedItem | undefined>(undefined)

  const { setToast } = useContext(AppContext)

  useEffect(() => {
    setNewsfeedItem(undefined)
  }, [newsfeedItemId])

  const { data: newsfeedPage, isValidating: loading } = useSWR(
    `${newsfeedItemId}`,
    fetcher,
    {
      revalidateOnMount: true,
    },
  )

  useEffect(() => {
    setNewsfeedItem(newsfeedPage)
  }, [newsfeedPage])

  const deletePost = (id: number) => {
    arcadeApiClient
      .delete(`multi_platform/newsfeed/${id}`)
      .then(() => {
        setNewsfeedItem(undefined)
      })
      .catch(e => {
        setToast({
          status: 'error',
          description: `Could not Delete Post: ${e}`,
        })
        console.error(e)
      })
  }

  return {
    newsfeedItem,
    loading,
    deletePost,
  }
}
