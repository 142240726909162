import useSWR from 'swr'
import arcadeApiClient from '../apiClients/arcadeApiClient'

const roleFetcher = (url: string) =>
  arcadeApiClient.get(url).then(res => res.data?.role)

export const useRoles = (authTokenPresent: boolean = true): string => {
  const { data: role } = useSWR('/multi_platform/profile/role', roleFetcher, {
    isPaused: () => !authTokenPresent,
  })

  return role
}
