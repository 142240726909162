import { useMemo } from 'react'
import useSWR from 'swr'
import { snakeCaseKeys } from '../utils/string'

import arcadeApiClient from '../apiClients/arcadeApiClient'
import { ArcadePlatform } from '../types'

const { ARCADE_PLATFORM_ID } = process.env
const { Slack, MSTeams } = ArcadePlatform

const fetcher = async url =>
  await arcadeApiClient.get(url).then(res => res.data)

export interface CurrentUser {
  id: number
  name: string
  firstName: string
  lastName: string
  teamName: string
  email: string
  personalEmail: string
  description: string
  birthday: string
  anniversary: string
  colorScheme: string
  jobTitle: string
  location: string
  phone: string
  personalPhone: string
  profileImage: string
  badgeImage: string
  isLocked: boolean
  needsToConnectGroupMe: boolean
  receiveEmails: boolean
  refetchCurrentUser: () => void
  updateCurrentUser: (changes: Partial<CurrentUser>) => void
}
export const useCurrentUser = (): CurrentUser | undefined => {
  const { data: currentUser, mutate: refetchCurrentUser } = useSWR(
    '/multi_platform/profile/info',
    fetcher,
  )

  const platformInfo = useMemo(() => {
    if (!currentUser) return null
    switch (ARCADE_PLATFORM_ID) {
      case Slack:
        return {
          email: window?.localStorage.getItem('arcade.email'),
          firstName: window?.localStorage.getItem('arcade.slack.firstName'),
          imageUrl: window?.localStorage.getItem('arcade.slack.imageUrl'),
          lastName: window?.localStorage.getItem('arcade.slack.lastName'),
          platform: 'slack',
        }
      case MSTeams:
        return {
          platform: 'teams',
        }
      default:
    }
  }, [currentUser])

  const updateCurrentUser = (changes: Partial<CurrentUser>) => {
    return arcadeApiClient
      .put(`/multi_platform/profile/${currentUser.id}`, {
        user: snakeCaseKeys(changes),
      })
      .then(res => {
        refetchCurrentUser(res.data)
      })
  }

  if (!currentUser) return
  return {
    refetchCurrentUser,
    updateCurrentUser,
    ...currentUser,
    id: currentUser?.id?.toString(),
    ...platformInfo,
  }
}
