export enum CourseStatus {
  Assigned = 'assigned',
  Completed = 'completed',
  InProgress = 'in_progress',
}

export interface Course {
  id: number
  title: string
  userStatus: CourseStatus
  mostRecentScore: null | number
}
