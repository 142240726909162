export const formatNumber = (number: number, withSign?: boolean): string => {
  const formattedNumber = Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number)
  if (withSign) {
    return Math.sign(number) < 0 ? formattedNumber : `+${formattedNumber}`
  }
  return formattedNumber
}

export const pluralize = (
  count: number,
  singular: string,
  customPlural?: string,
): string => {
  if (count === 1) return singular
  if (customPlural) return customPlural
  return `${singular}s`
}

export const getOrdinal = (number: number): string => {
  const ordinal = ['th', 'st', 'nd', 'rd']
  const v = number % 100
  return number + (ordinal[(v - 20) % 10] || ordinal[v] || ordinal[0])
}
const months = {
  Jan: '1',
  Feb: '2',
  Mar: '3',
  Apr: '4',
  May: '5',
  Jun: '6',
  Jul: '7',
  Aug: '8',
  Sep: '9',
  Oct: '10',
  Nov: '11',
  Dec: '12',
}
export const formatDate = (date: string): string => {
  const monthFormat: string = months[date?.substring(5, 8)]
  const dateFormat = `${date?.substring(0, 4)} ${monthFormat}-${
    date?.substring(9).split(',')[0]
  }-${date?.substring(9).split(',')[1].trim()}`

  return !dateFormat.includes('undefined') ? dateFormat : ''
}
