import {
  ElementType,
  ReactElement,
  ReactEventHandler,
  SyntheticEvent,
} from 'react'

export interface NavigationLink {
  as?: ElementType
  label: string
  href?: string
  isActive: boolean
  isDisabled?: boolean
  isExternal?: boolean
  icon?: ReactElement
  onClick?: ReactEventHandler
  subLinks?: NavigationLink[]
  renderAsButton?: boolean
}

export interface NavigationProfile extends NavigationLink {
  hasProfileImage: boolean
  imageUrl: string
  name: string
}

export interface NavigationLogo {
  as?: ElementType
  element: ReactElement
  mobileElement?: ReactElement
  href?: string
  renderAsButton?: boolean
  onClick?: (event: SyntheticEvent) => void
}

export enum Size {
  MobileHeight = '50px',
  DesktopHeight = '57px',
  MaxWidth = '1240px',
}
