// Blue
export const chonky = {
  100: '#E2F3F6',
  200: '#9AEBFB',
  300: '#65E2F7',
  400: '#20D7F4',
  500: '#44D0EF',
  600: '#30BCDB',
  700: '#1CA8C7',
  800: '#1389A4',
  900: '#0A5B6C',
}

// Navy
export const luigisPants = {
  100: '#CEDAEA',
  200: '#8D9FB7',
  300: '#597196',
  400: '#294874',
  500: '#05255B',
  600: '#001147',
  700: '#020E34',
  800: '#020A23',
  900: '#010614',
}

// Red
export const kirby = {
  100: '#FFD8E1',
  200: '#FFADC1',
  300: '#FF85A2',
  400: '#FF5C83',
  500: '#FF3364',
  600: '#D02951',
  700: '#AD1338',
  800: '#830F2B',
  900: '#590217',
}

// Purple
export const spyro = {
  100: '#F3EEF9',
  200: '#D0BDE7',
  300: '#B89BDB',
  400: '#A17ACF',
  500: '#8959C3',
  600: '#7146A5',
  700: '#542D82',
  800: '#35145B',
  900: '#240647',
}

// Orange
export const bandicoot = {
  100: '#FFF0EF',
  200: '#FFC7C1',
  300: '#FFA39D',
  400: '#FF857D',
  500: '#FF665C',
  600: '#E55047',
  700: '#CD3A32',
  800: '#A4231B',
  900: '#780B05',
}

// Yellow
export const samus = {
  100: '#FFF9E8',
  200: '#FFE6A5',
  300: '#FFDA78',
  400: '#FFCD4B',
  500: '#FFC11E',
  600: '#E3A90F',
  700: '#C89409',
  800: '#956F0A',
  900: '#644900',
}

// Green
export const venasaur = {
  100: '#E6F8F8',
  200: '#9BE4E1',
  300: '#69D6D3',
  400: '#37C9C4',
  500: '#05BBB5',
  600: '#0B9C97',
  700: '#077773',
  800: '#075552',
  900: '#00403E',
}

// Gray
export const cloud = {
  100: '#F8F8FB',
  200: '#EDEFF9',
  300: '#E3E5F2',
  400: '#D0D2E5',
  500: '#BABCD3',
  600: '#A4A7C2',
  700: '#9193AB',
  800: '#6D6E80',
  900: '#4E4E5E',
}

// Black
export const blackAlpha = {
  500: 'rgb(0, 0, 0, 0.36)',
}

export const black = '#232222'
export const white = '#ffffff'
export const gray = cloud

export const gradient = {
  sunrise:
    'radial-gradient(193.29% 161.02% at -6.18% -43.58%, #B182EA 0%, #FF3364 100%), radial-gradient(210.37% 170% at -8.65% 0%, #B182EA 0%, #FF3364 100%)',
  sunset:
    'linear-gradient(309.03deg, #FF3364 -13.35%, #FF7265 88.27%), linear-gradient(309.72deg, #FF3364 -13.28%, #FF7265 105.42%)',
  blue: 'radial-gradient(70.32% 70.32% at 50.02% 29.68%, #0E49AE 0%, #05255B 100%)',
  darkModeBanner:
    'linear-gradient(269.42deg, #18366F 0.28%, #4B4695 50.95%, #8959C3 113.16%)',
  darkModeBackground:
    'linear-gradient(180deg, rgba(5, 37, 91, 0.8) 0%, rgba(5, 37, 91, 0) 100%), radial-gradient(87% 87% at 17.88% 13%, rgba(11, 62, 147, 0.6) 0%, rgba(11, 62, 147, 0) 100%), radial-gradient(88.4% 55.8% at 62.19% 51.75%, rgba(137, 89, 195, 0.6) 0%, rgba(137, 89, 195, 0) 100%), #000000',
  darkModeLeaderboardBackground:
    'linear-gradient(to bottom, #4b3a39 0%,#3f2064 0%,#05255b 32%), #05255b',
}

export const text = {
  primary: '#4E4E5E',
  secondary: '#5F627D',
  tertiary: '#A4A7C2',
  quarternary: '#D0D2E5',
  primaryDark: '#05255B',
  primaryWhite: white,
  secondaryWhite: 'rgba(255, 255, 255, 0.72)',
  tertiaryWhite: 'rgba(255, 255, 255, 0.48)',
  quarternaryWhite: 'rgba(255, 255, 255, 0.32)',
}

export const alert = {
  error: 'rgba(255, 51, 100, 0.1)',
}

// these are for alerts/toasts
export const red = {
  100: kirby['100'],
  200: kirby['200'],
  300: kirby['300'],
  400: kirby['400'],
  500: kirby['500'],
  600: kirby['600'],
  700: kirby['700'],
  800: kirby['800'],
  900: kirby['900'],
}
export const orange = {
  100: bandicoot['100'],
  200: bandicoot['200'],
  300: bandicoot['300'],
  400: bandicoot['400'],
  500: bandicoot['500'],
  600: bandicoot['600'],
  700: bandicoot['700'],
  800: bandicoot['800'],
  900: bandicoot['900'],
}
export const green = {
  100: venasaur['100'],
  200: venasaur['200'],
  300: venasaur['300'],
  400: venasaur['400'],
  500: venasaur['500'],
  600: venasaur['600'],
  700: venasaur['700'],
  800: venasaur['800'],
  900: venasaur['900'],
}
export const blue = {
  100: spyro['100'],
  200: spyro['200'],
  300: spyro['300'],
  400: spyro['400'],
  500: spyro['500'],
  600: spyro['600'],
  700: spyro['700'],
  800: spyro['800'],
  900: spyro['900'],
}

export const border = {
  primary: cloud['300'],
  hover: cloud['500'],
  active: chonky['500'],
  primaryWhite: white,
}

export const darkMode = {
  400: '#203976',
  500: '#223977',
  700: '#162859',
}
