export enum Feature {
  AddAllToManagedTeams = 'add_all_to_managed_teams',
  BulkTableActions = 'bulk_table_actions',
  GameCardsUpgrade = 'game_cards_upgrade',
  GameEscrow = 'game_escrow',
  ManagersMergeUsers = 'managers_merge_users',
  MicrolearningModules = 'microlearning_modules',
  NotificationCenter = 'notification_center',
  NotificationModal = 'notification_modal',
  PlayerIdentifiers = 'player_identifiers',
  TeamRewardOptions = 'team_reward_options',
}
