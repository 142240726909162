import { FC, PropsWithChildren, useContext, useEffect, useState } from 'react'
import cubejs from '@cubejs-client/core'
import { CubeProvider } from '@cubejs-client/react'
import useSWR from 'swr'
import { arcadeApiClient } from '../apiClients'
import { AuthContext } from './MPAuthContextProvider'
import { AUTH_STORAGE_KEY } from 'ui/types'
import { useRoles } from '../hooks'
import { userRoleIsAtLeast } from '../helpers/roles'

const tokenFetcher = async (url: string): Promise<any> =>
  await arcadeApiClient.get(url).then(res => res.data.token)

export const CubeContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const role = useRoles()
  const hasCube =
    role &&
    userRoleIsAtLeast({
      requiredRole: 'company_manager',
      userRole: role,
    })
  const { auth } = useContext(AuthContext)
  const { data: CUBEJS_TOKEN } = useSWR(
    `multi_platform/manage/reporting/token#${auth}${hasCube}`,
    tokenFetcher,
    {
      isPaused: () => !auth || !hasCube,
    },
  )
  const [cubejsApi, setCubejsApi] = useState<any>()
  const arcadeAuth = JSON.parse(
    window.localStorage.getItem(AUTH_STORAGE_KEY) || '{}',
  )
  const endpoint = arcadeAuth.endpoint || process.env.ARCADE_API_ENDPOINT
  const CUBE_API_URL = !!endpoint.includes('rtpos')
    ? process.env.RTPOS_CUBE_API_URL
    : process.env.CUBE_JS_API_URL

  useEffect(() => {
    if (CUBEJS_TOKEN) {
      setCubejsApi(
        cubejs(CUBEJS_TOKEN, {
          apiUrl: `${CUBE_API_URL}/cubejs-api/v1`,
        }),
      )
    }
  }, [CUBEJS_TOKEN])

  return <CubeProvider cubejsApi={cubejsApi}>{children}</CubeProvider>
}
